import React, { useEffect, useState } from "react";
import "./blog.css";
import newsletter from "../../../../assets/images/newsletter.svg";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";

export default function Blog() {
  const [data, setData] = useState([]);

  const navigate = useNavigate()
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/articles?populate=*`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        setData(data.data);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="blog-c">
      <h2>News & Articles</h2>
      <div className="blog-c-c">
        <div className="blog-side-a">
          <div>
            {data?.slice(0, 3).map((item, index) => (
              <div
                key={index}
                className="blog-card"
                onClick={() => {
                  const postTitle = item?.attributes?.title
                    ?.replace(/\s+/g, "-")
                    .replace("?", "");
                  navigate(
                    {
                      pathname: `/post/${postTitle}`,
                      search: createSearchParams({
                        id: item.id,
                      }).toString(),
                    },
                    { state: item }
                  );
                }}
              >
                <div className="blog-card-img-c">
                  <img
                    src={
                      SERVER_DOMAIN +
                      item?.attributes?.image?.data?.attributes?.url
                    }
                  />
                </div>
                <div>
                  <p className="blog-card-date">
                    {new Date(item.attributes.createdAt).toLocaleDateString(
                      "TR"
                    )}
                  </p>
                  <h3 className="blog-card-title">{item.attributes.title}</h3>
                  <p className="blog-card-des">
                    {item.attributes.subTitle.length > 100
                      ? item.attributes.subTitle.slice(0, 90) + "..."
                      : item.attributes.subTitle}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="blog-side-b">
          <img src={newsletter} />
          <div className="blog-side-b-c-c">
            <h3>Subscribe To Articles</h3>
            <div className="flex" style={{ gap: 7, marginTop: 15 }}>
              <input
                placeholder="your@email.com"
                className="newsletter-input"
              />
              <div className="newsletter-btn flex">
                <SendIcon sx={{ color: "var(--primary)" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
