import React, { useEffect, useRef, useState } from "react";
import "./hero.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import flame from "../../../../assets/icons/flame-icon.svg";
import { Link, useSearchParams } from "react-router-dom";

export default function Hero() {
  const apiKey = process.env.REACT_APP_API_KEY;

  const [data, setData] = useState([]);
  const [medium, setMedium] = useState(false);
  const fMajor = useRef();
  const fMinor = useRef();
  const gold = useRef();
  const index = useRef();
  const oil = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  let param = searchParams.get("s");
  useEffect(() => {
    // if (param == "Forex-Major") {
    //   fMajor.current.scrollIntoView({
    //     behavior: "smooth",
    //   });
     
    // } else if (param == "Forex-Minor") {
    //   fMinor.current.scrollIntoView({
    //     behavior: "smooth",
    //   });
    //   setTimeout(() => {
    //     window.scrollBy({ top: 400, behavior: "smooth" });
    //   }, 500);
    // } else if (param == "Metals") {
    //   gold.current.scrollIntoView({
    //     behavior: "smooth",
    //   });
    //   setTimeout(() => {
    //     window.scrollBy({ top: 600, behavior: "smooth" });
    //   }, 500);
    // } else if (param == "Index") {
    //   index.current.scrollIntoView({
    //     behavior: "smooth",
    //   });
    //   setTimeout(() => {
    //     window.scrollBy({ top: 300, behavior: "smooth" });
    //   }, 500);
    // } else if (param == "Oil") {
    //   oil.current.scrollIntoView({
    //     behavior: "smooth",
    //   });
    //   setTimeout(() => {
    //     window.scrollBy({ top: -300, behavior: "smooth" });
    //   }, 500);
    // }

    async function fetchData() {
      try {
        const response = await fetch(`${API}/instrument`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const updateImageSource = () => {
      if (window.innerWidth < 1145) {
        setMedium(true);
      } else {
        setMedium(false);
      }
    };

    window.addEventListener("load", updateImageSource);
    window.addEventListener("resize", updateImageSource);

    return () => {
      window.removeEventListener("load", updateImageSource);
      window.removeEventListener("resize", updateImageSource);
    };
  }, []);

  return (
    <div className="ins-h-c">
      <h1>Instruments</h1>
      <img
        src={require("../../../../assets/images/ins-bg.png")}
        className="ins-bg"
      />

      <div className="ins-s">
        <div className="ins-s-1-side-a">
          <h2>{data.data && data.data[0]?.sections[0]?.title}</h2>
          <p>{data.data && data.data[0]?.sections[0]?.desc}</p>
          <Link
            to={data.data && data.data[0]?.buttons[0]?.link}
            className="hero-btn"
            style={{ marginLeft: 0 }}
          >
            <img src={flame} alt="Flame Icon" />
            <p>{data.data && data.data[0]?.buttons[0]?.text}</p>
          </Link>
        </div>
        <div className="ins-s-1-side-b">
          <img src={data.images && SERVER_DOMAIN + data.images[0].imgs[0]} />
        </div>
      </div>

      <div className="ins-s-2">
        <div className="ins-s-2-side-a">
          <h2>{data.data && data.data[0]?.sections[1]?.title}</h2>
          <p>{data.data && data.data[0]?.sections[1]?.desc}</p>
        </div>
        <div className="ins-s-2-side-b">
          <img
            src={
              medium
                ? require("../../../../assets/images/ins-laptop.png")
                : data.images && SERVER_DOMAIN + data.images[0].imgs[1]
            }
          />
        </div>

        <div className="ins-roadmap">
          <img
            ref={fMajor}
            src={require("../../../../assets/icons/arrow-pointer-icon.png")}
          />
          <div></div>
        </div>
      </div>

      <div className="ins-s-2 ins-s-3" ref={fMinor}>
        <div className="ins-s-2-side-a ins-s-3-side-a">
          <h2>{data.data && data.data[0]?.sections[2]?.title}</h2>
          <p>{data.data && data.data[0]?.sections[2]?.desc}</p>
        </div>
        <div className="ins-s-2-side-b ins-s-3-side-b">
          <img src={data.images && SERVER_DOMAIN + data.images[0].imgs[2]} />
        </div>

        <div className="ins-roadmap flip-hor">
          <img
            src={require("../../../../assets/icons/arrow-pointer-icon.png")}
          />
          <div></div>
        </div>
      </div>

      <div className="ins-s-2">
        <div className="ins-s-2-side-a">
          <h2>{data.data && data.data[0]?.sections[3]?.title}</h2>
          <p>{data.data && data.data[0]?.sections[3]?.desc}</p>
        </div>
        <div className="ins-s-2-side-b ins-s-4-side-b">
          <img src={data.images && SERVER_DOMAIN + data.images[0].imgs[3]} />

          <div className="ins-btns-c">
            <Link
              ref={gold}
              to={data.data && data.data[0]?.buttons[1]?.link}
              className="hero-btn"
              style={{ marginLeft: 0 }}
            >
              <img src={flame} alt="Flame Icon" />
              <p>{data.data && data.data[0]?.buttons[1]?.text}</p>
            </Link>
            <Link
              to={data.data && data.data[0]?.buttons[2]?.link}
              className="ins-outlined-btn"
              style={{ marginLeft: 0 }}
            >
              <p>{data.data && data.data[0]?.buttons[2]?.text}</p>
            </Link>
          </div>
        </div>

        <div className="ins-roadmap">
          <img
            src={require("../../../../assets/icons/arrow-pointer-icon.png")}
          />
          <div></div>
        </div>
      </div>

      <div className="ins-s-2 ins-s-3">
        <div className="ins-s-2-side-a ins-s-3-side-a">
          <h2>{data.data && data.data[0]?.sections[4]?.title}</h2>
          <p ref={index}>{data.data && data.data[0]?.sections[4]?.desc}</p>
        </div>
        <div className="ins-s-2-side-b ins-s-4-side-b">
          <h2>
            trade
            <br />
            <span>the way</span>
            <br />
            you wish
          </h2>
        </div>

        <div className="ins-roadmap flip-hor ins-roadmap-last">
          <img
            src={require("../../../../assets/icons/arrow-pointer-icon.png")}
          />
          <div></div>
        </div>
      </div>

      <div className="ins-s-2" ref={oil}>
        <div className="ins-s-2-side-a">
          <h2>{data.data && data.data[0]?.sections[5]?.title}</h2>
          <p>{data.data && data.data[0]?.sections[5]?.desc}</p>
        </div>
        <div className="ins-s-2-side-b ins-s-5-side-b">
          <img
            src={
              medium
                ? require("../../../../assets/images/ins-oil.jpg")
                : data.images && SERVER_DOMAIN + data.images[0].imgs[4]
            }
          />
        </div>
        <div className="ins-roadmap">
          <img
            src={require("../../../../assets/icons/arrow-pointer-icon.png")}
          />
        </div>
      </div>
    </div>
  );
}
