import React from "react";
import "./offices.css";
import Slider from "react-slick";
import location from "../../../../assets/icons/ab-location-icon.svg";
import call from "../../../../assets/icons/call-icon.svg";
import { SERVER_DOMAIN } from "../../../../assets/staticData";

export default function Offices({ data }) {
 

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="ab-of-c">
      <h2>{data.aboutData && data.aboutData[0]?.officesHeading}</h2>

      <div className="ab-of-card-c">
        {data.aboutData &&
          data.aboutData[0]?.offices?.map((item, index) => (
            <div key={index} className="ab-of-card">
              <div>
                <Slider className="ab-of-slider" {...settings}>
                  {item.images
                    ?.slice(0, item.images.length - 1)
                    .map((item, index) => {
                      return (
                        <div key={index}>
                          <img
                            className="ab-of-slider-img"
                            src={SERVER_DOMAIN + item}
                          />
                        </div>
                      );
                    })}
                </Slider>
              </div>
              <div className="ab-of-card-map">
                <iframe
                  width="400"
                  height="100"
                  frameborder="0"
                  id="gmap_canvas"
                  src={`https://maps.google.com/maps?q=${item.lat},${item.long}&hl=en&z=11&output=embed`}
                ></iframe>
                <script
                  type="text/javascript"
                  src="https://embedmaps.com/google-maps-authorization/script.js?id=37255d337f4e58289d93f8f41ddf2cc57f402596"
                ></script>
              </div>
              <div className="ab-of-card-bottom">
                <p>{item.label}</p>
                <div className="ab-of-card-bootom-mc" style={{ marginTop: 20 }}>
                  <img src={call} />
                  <p>{item.phone}</p>
                </div>
                <div className="ab-of-card-bootom-mc">
                  <img src={location} />
                  <p>{item.address}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
