import React, { useEffect, useState } from "react";
import "./meta.css";
import windows from "../../../../assets/images/windows-btn.svg";
import google from "../../../../assets/images/google-btn.svg";
import apple from "../../../../assets/images/apple-btn.svg";
import apk from "../../../../assets/images/apk-btn.svg";
import linux from "../../../../assets/images/linux-btn.svg";
import mac from "../../../../assets/images/mac-btn.svg";
import macD from "../../../../assets/images/mac-btn-d.svg";
import windowsD from "../../../../assets/images/windows-btn-d.svg";
import Platforms from "../../../home/sections/platforms/Platforms";
import parse from "html-react-parser";
import { SERVER_DOMAIN } from "../../../../assets/staticData";

export default function Meta({data}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="p-meta-c flex">
        <div className="p-meta-side-a">
          <h2>MetaTrader 5</h2>
          {data.platformData &&
            parse(`${data?.platformData[0]?.metaParagraph}`)}
        </div>
        <div className="p-meta-side-b">
          <img src={data.images && SERVER_DOMAIN + data.images[0].metaImg} />
        </div>
      </div>

      {windowWidth < 1280 ? (
        <div style={{ marginTop: -50, marginBottom: -50 }}>
          <Platforms notHome />
        </div>
      ) : (
        <div
          className="flex-col"
          style={{ alignItems: "flex-start", maxWidth: 1200, margin: "0 auto" }}
        >
          <div className="flex download-btn-c" style={{ marginLeft: -16 }}>
            <h3>Desktop</h3>
            <div
              className="vertical-divider"
              style={{ marginBottom: -17 }}
            ></div>
            <img src={windows} className="download-img-btn" />
            <img src={mac} className="download-img-btn" />
            <img src={linux} className="download-img-btn" />
            <img src={macD} className="download-img-btn" />
            <img src={windowsD} className="download-img-btn" />
          </div>
          <div className="flex download-btn-c" style={{ marginTop: -17 }}>
            <h3>Mobile</h3>
            <div className="vertical-divider"></div>
            <img src={apple} className="download-img-btn" />
            <img src={google} className="download-img-btn" />
            <img src={apk} className="download-img-btn" />
          </div>
        </div>
      )}
    </div>
  );
}
