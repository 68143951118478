import React, { useEffect, useState } from "react";
import "./hero.css";
import { API } from "../../../../assets/staticData";
import { Link } from "react-router-dom";
import polygon from "../../../../assets/images/polygon.svg";
import parse from "html-react-parser";

const apiKey = process.env.REACT_APP_API_KEY;

export default function Hero() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/account-type`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();
        setData(res.data?.attributes?.accountTypesData[0]?.menu);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="at-h-c">
      <h1>Account Types</h1>

      <div className="at-h-plans-c">
        {data.length > 0 &&
          data.slice(0, 3).map((item, index) => (
            <div key={index} className="at-plan-box-c">
              <div className="at-plan-c">
                <div className="at-plan-c-c">
                  <p>{item.title}</p>
                  <div className="divider"></div>
                  <div className="plan-items-c">
                    {item.dropDownItems?.map((item, index) => (
                      <div key={index} className="flex" style={{ gap: 10 }}>
                        <div>
                          <p className="plan-label-text">{"• " + item.title}</p>
                          <p className="plan-value-text">{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className="plan-bg-dropdown"
                  style={{
                    backgroundColor:
                      (index == 2 && "#d1dbff") || (index == 1 && "#e0e7ff"),
                  }}
                ></div>
              </div>
              <div className="at-h-box flex-col">
                {item.desc && parse(`${item.desc}`)}

                <div className="at-h-btn-c">
                  <Link to={item.btn1L}>{item.btn1T}</Link>
                  <Link to={item.btn2L}>{item.btn2T}</Link>
                </div>
              </div>
            </div>
          ))}

        <img src={polygon} className="at-polygon" />
      </div>
    </div>
  );
}
