import React, { useEffect, useState } from "react";
import "./videos.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import VideoPlayer from "../../../../components/video-player/VideoPlayer";

export default function Videos() {
  const [data, setData] = useState();
  const [videoPlayer, setVideoPlayer] = useState(null);
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/help-center`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data?.data?.attributes?.vidLinks[0]?.vids);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="help-vids-c">
      <h2>
        <span>tutorial</span> videos
      </h2>

      <div className="help-vids-c-c">
        {data?.map((item, index) => (
          <div
            onClick={() => setVideoPlayer(item?.link)}
            key={index}
            className="help-vid flex"
            style={{
              height: index == 0 ? "99.5%" : "47%",
              width: index == 0 ? "46%" : "25%",
            }}
          >
            <img
              src={
                item.thumbnail
                  ? SERVER_DOMAIN + item.thumbnail
                  : require("../../../../assets/images/video-thumbnail.png")
              }
              className="vid-thumbnail"
            />
            <img
              style={{ width: "25%", position: "relative" }}
              src={require("../../../../assets/icons/play-icon.png")}
            />

            <p>
              {item.title?.length > 28
                ? item.title.slice(0, 28) + "..."
                : item.title}
            </p>
          </div>
        ))}
      </div>
      <div className="outlined-btn flex">See All Videos</div>
      {videoPlayer && (
        <VideoPlayer setVideoPlayer={setVideoPlayer} link={videoPlayer} />
      )}
    </div>
  );
}
