import React, { useEffect, useState } from "react";
import Hero from "./sections/hero/Hero";
import Counters from "../home/sections/counters/Counters";
import History from "./sections/history/History";
import How from "./sections/how/How";
import Offices from "./sections/offices/Offices";
import Why from "./sections/why/Why";
import Question from "./sections/question/Question";
import { API, SERVER_DOMAIN } from "../../assets/staticData";
import { Helmet } from "react-helmet-async";

export default function About({ seo }) {
  const apiKey = process.env.REACT_APP_API_KEY;
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/about-us`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {seo[1]?.metaTitle ? seo[1]?.metaTitle : "Mishov Markets"}
        </title>
        <meta name="description" content={seo[1]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[1]?.socialTitle} />
        <meta property="og:description" content={seo[1]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[1]?.socialImg} />
        <meta name="robots" content={seo[1]?.indexingRules} />
        <meta name="keywords" content={seo[1]?.keywords} />
        <script type="application/ld+json">{seo[1]?.schema}</script>
      </Helmet>
      <Hero data={data} />
      <Counters />
      <History data={data} />
      <How data={data} />
      <Offices data={data} />
      <Why data={data} />
      <Question />
    </div>
  );
}
