import React, { useEffect, useState } from "react";
import "./history.css";
import flame from "../../../../assets/icons/flame-icon.svg";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import parse from "html-react-parser";

export default function History({ data }) {
  return (
    <div className="ab-history-c flex">
      <div className="ab-hi-side-a">
        <img
          src={data.images ? SERVER_DOMAIN + data.images[0]?.historyImg : ""}
        />
        <img
          className="ab-badge"
          src={data.images ? SERVER_DOMAIN + data.images[0]?.historyBadge : ""}
        />
      </div>
      <div className="ab-hi-side-b">
        <h2>history</h2>
        {data.aboutData && parse(`${data?.aboutData[0]?.aboutUs}`)}
        <p style={{ fontSize: 18 }}>{`2015 - ${new Date().getFullYear()}`}</p>
        <div className="hero-btn">
          <img src={flame} />
          <p>Start Trade</p>
        </div>
      </div>
    </div>
  );
}
