import React, { useEffect, useState } from "react";
import Hero from "./sections/hero/Hero";
import Why from "./sections/why/Why";
import Question from '../about/sections/question/Question'
import { API, SERVER_DOMAIN } from "../../assets/staticData";
import { Helmet } from "react-helmet-async";

const apiKey = process.env.REACT_APP_API_KEY;


export default function MC({seo}) {

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/mc`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {seo[10]?.metaTitle ? seo[10]?.metaTitle : "Mishov Markets"}
        </title>
        <meta name="description" content={seo[10]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[10]?.socialTitle} />
        <meta property="og:description" content={seo[10]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[10]?.socialImg} />
        <meta name="robots" content={seo[10]?.indexingRules} />
        <meta name="keywords" content={seo[10]?.keywords} />
        <script type="application/ld+json">{seo[10]?.schema}</script>
      </Helmet>
      <Hero data={data} />
      <Why data={data} />
      <Question />
    </div>
  );
}
