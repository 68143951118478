import React, { useState } from "react";
import "./question.css";
import { TextField } from "@mui/material";

import Select from "react-select";
import { Link } from "react-router-dom";

const options = [
  { value: "Trading", label: "Trading" },
  { value: "Forex", label: "Forex" },
  { value: "Platforms", label: "Platforms" },
  { value: "Privacy Policy", label: "Privacy Policy" },
  { value: "Report an Issue", label: "Report an Issue" },
];

export default function Question() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [notif, setNotif] = useState(null);
  const [formInput, setFormInput] = useState({
    name: "",
    email: "",
    message: "",
  });

  const submitMessage = () => {
    if (
      formInput.name == "" ||
      formInput.email == "" ||
      formInput.message == ""
    ) {
      setNotif(1);
    } else {
      setNotif(2);
    }
  };

  return (
    <div className="ab-q-c">
      <h2>Have a Question?</h2>

      <div className="ab-q-c-c flex">
        <div className="ab-q-side-a flex-col">
          <TextField
            className="ab-q-input"
            variant="outlined"
            label="Name"
            value={formInput.name}
            onChange={(e) => {
              setFormInput({ ...formInput, name: e.target.value });
              setNotif(null);
            }}
          />
          <TextField
            className="ab-q-input"
            type="email"
            variant="outlined"
            label="Email"
            value={formInput.email}
            onChange={(e) => {
              setFormInput({ ...formInput, email: e.target.value });
              setNotif(null);
            }}
          />
          <Select
            placeholder="Select a Topic"
            className="ab-q-select"
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
          />
        </div>
        <div className="ab-q-side-b flex-col">
          <TextField
            className="ab-q-input"
            variant="outlined"
            label="Your Message..."
            multiline
            rows={7}
            value={formInput.message}
            onChange={(e) => {
              setFormInput({ ...formInput, message: e.target.value });
              setNotif(null);
            }}
          />

          <div className="flex" style={{ width: "85%" }}>
            <div className="form-submit-btn" onClick={submitMessage}>
              Submit
            </div>

            <h3 style={{ margin: "12px auto" }}>or</h3>
            <Link to={"/Help-Center"} className="ab-outline-btn">Visit FAQ Page</Link>
          </div>
          <div
            className="ab-form-notif flex"
            style={{
              visibility: notif == null ? "hidden" : "visible",
              backgroundColor:
                notif == null ? null : notif == 1 ? "red" : "green",
              height: notif == null ? 0 : 35,
            }}
          >
            {notif == 1 &&
              "Please fill all the required fields before submitting"}
            {notif == 2 && "Your message has been sent successfully"}
          </div>
        </div>
      </div>
    </div>
  );
}
