import React, { useEffect, useState } from 'react'
import Hero from './sections/hero/Hero'
import Meta from './sections/meta/Meta'
import Why from './sections/why/Why'
import What from './sections/what/What'
import Question from "../about/sections/question/Question";
import { API, SERVER_DOMAIN } from '../../assets/staticData'
import { Helmet } from 'react-helmet-async'

const apiKey = process.env.REACT_APP_API_KEY;


export default function Platforms({seo}) {

   const [data, setData] = useState([]);

   useEffect(() => {
     async function fetchData() {
       try {
         const response = await fetch(`${API}/platform`, {
           headers: {
             "Content-Type": "application/json",
             Authorization: `Bearer ${apiKey}`,
           },
           method: "GET",
         });
         if (!response.ok) {
           throw new Error("Failed to fetch data");
         }
         const data = await response.json();
         setData(data.data?.attributes);
       } catch (error) {
         console.error("Error fetching data:", error);
       }
     }

     fetchData();
   }, []);

  return (
    <div>
      <Helmet>
        <title>
          {seo[3]?.metaTitle ? seo[3]?.metaTitle : "Mishov Markets"}
        </title>
        <meta name="description" content={seo[3]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[3]?.socialTitle} />
        <meta property="og:description" content={seo[3]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[3]?.socialImg} />
        <meta name="robots" content={seo[3]?.indexingRules} />
        <meta name="keywords" content={seo[3]?.keywords} />
        <script type="application/ld+json">{seo[3]?.schema}</script>
      </Helmet>
      <Hero data={data} />
      <Meta data={data} />
      <Why data={data} />
      <What data={data} />
      <Question />
    </div>
  );
}
