import React, { useEffect, useState } from "react";
import "./instructor.css";
import { useNavigate } from "react-router";
import { API } from "../../../../assets/staticData";
import { createSearchParams } from "react-router-dom";

export default function Instructor({ item }) {
  const [data, setData] = useState([]);
  const [included, setIncluded] = useState();
  const [matchingInstructorData, setMatchingInstructorData] = useState([]);
const apiKey = process.env.REACT_APP_API_KEY;
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/instructors`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();
        setData(res.data);

        // setIncluded(
        //   res.data.some((itm) =>
        //     itm.attributes.instructorData.some(
        //       (instructor) => instructor.post.id === item.id
        //     )
        //   )
        // );
        const findMatchingInstructorData = (data, pageId) => {
          for (const item of data) {
            for (const instructor of item.attributes.instructorData) {
              if (instructor.post.id === pageId) {
                return item.attributes.instructorData;
              }
            }
          }
          return [];
        };

        const matchingData = findMatchingInstructorData(res.data, item.id);
        setMatchingInstructorData(matchingData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [item.id]);

  return (
    <div>
      {matchingInstructorData.length > 0 ? (
        <div className="p-i-c flex-col">
          <div>
            {matchingInstructorData.map((itm, index) => (
              <div
                key={index}
                className="p-i-i flex-col"
                onClick={() => {
                  const postTitle = itm?.post?.attributes?.title
                    ?.replace(/\s+/g, "-")
                    .replace("?", "");
                  navigate(
                    {
                      pathname: `/post/${postTitle}`,
                      search: createSearchParams({
                        id: itm.post?.id,
                      }).toString(),
                    },
                    { state: itm.post }
                  );
                }}
              >
                <div
                  style={{
                    background:
                      itm?.post?.id == item.id ? "var(--green)" : "#236af2",
                  }}
                  className="p-i-blue-ball flex"
                >
                  {index + 1}
                </div>
                <p
                  style={{
                    color: itm?.post?.id == item.id ? "var(--green)" : "black",
                  }}
                >
                  {itm.label}
                </p>
                {index < matchingInstructorData?.length - 1 && (
                  <div className="p-i-blue-line"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
