import React, { useEffect, useState } from "react";
import "./call.css";
import mailIcon from "../../../../assets/icons/mail-icon.svg";
import phoneIcon from "../../../../assets/icons/phone-icon.svg";
import messageIcon from "../../../../assets/icons/message-icon.svg";
import { Link } from "react-router-dom";
import { API } from "../../../../assets/staticData";

const apiKey = process.env.REACT_APP_API_KEY;

export default function Call() {
  const [data, setData] = useState([
    {
      title: "Reach us via",
      link: "",
      linkText: "",
      subTitle: "We will respond to you within 24 hours",
      icon: mailIcon,
    },
    {
      title: "Customer Support No",
      link: "",
      linkText: "",
      subTitle: "We are available 24/5",
      icon: phoneIcon,
    },
    {
      title: "Start chatting with our experts",
      link: "",
      linkText: "",
      subTitle: "We are online",
      icon: messageIcon,
    },
  ]);
 



  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/contact-us`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

          setData((prevInfo) => [
            {
              ...prevInfo[0],
              linkText: data.data?.attributes?.contactData[0].email,
              link: `mailto:${data.data?.attributes?.contactData[0].email}`,
            },
            {
              ...prevInfo[1],
              linkText: data.data?.attributes?.contactData[0].phone,
              link: `tel:${data.data?.attributes?.contactData[0].phone}`,
            },
            prevInfo[2],
          ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="h-ca-c">
      <h3>mishov call center</h3>

      {data.map((item, index) => (
        <Link
          to={item.link}
          key={index}
          className="help-call-i flex"
          style={{
            marginLeft: index == 0 ? 190 : "auto",
            marginRight: index == 2 ? 190 : "auto",
          }}
        >
          <div className="help-call-i-blue flex">
            <img src={item.icon} />
          </div>
          <div className="help-call-i-white flex-col">
            <div style={{ paddingLeft: 30 }} className="flex call-padding-res">
              <p>{item.title}&nbsp;</p>
              <p> </p>
              <p style={{ textDecoration: "underline", color: "black" }}>
                {item.linkText}
              </p>
            </div>
            <p className="help-call-i-subtitle">{item.subTitle}</p>
          </div>
          <div className="help-call-blue-bg"></div>
        </Link>
      ))}
    </div>
  );
}
