import "./App.css";
import Nav from "./components/nav/Nav";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import { Route, Routes } from "react-router";
import Platforms from "./pages/platforms/Platforms";
import Footer from "./pages/home/sections/footer/Footer";
import PLC from "./pages/plc/PLC";
import MC from "./pages/mc/MC";
import SC from "./pages/sc/SC";
import Help from "./pages/help/Help";
import "./quill.css";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { useEffect, useState } from "react";
import { API } from "./assets/staticData";
import FourOFour from "./components/404/404";
import Login from "./components/login/Login";
import Blog from "./pages/blog/Blog";
import Terms from "./pages/terms/Terms";
import "quill/dist/quill.snow.css";
import Post from "./pages/post/Post";
import Author from "./pages/author/Author";
import AccountTypes from "./pages/account-types/AccountTypes";
import DandW from "./pages/d&w/D&W";
import Instruments from "./pages/instruments/Instruments";
import Promotion from "./pages/promotion/Promotion";
import FAQ from "./pages/faq/FAQ";

function App() {
  const [seo, setSeo] = useState([]);

  const loginToken = sessionStorage.getItem("loginToken");

  const apiKey = process.env.REACT_APP_API_KEY;


     useEffect(() => {
       async function fetchData() {
         try {
           const response = await fetch(`${API}/seo`, {
             headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${apiKey}`,
             },
             method: "GET",
           });
           if (!response.ok) {
             throw new Error("Failed to fetch data");
           }
           const data = await response.json();
           setSeo(data.data?.attributes?.seoData[0]?.pages);
         } catch (error) {
           console.error("Error fetching data:", error);
         }
       }

       fetchData();
     }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/theme`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        document.documentElement.style.setProperty(
          "--primary",
          data.data?.attributes?.themeData[0]?.primary?.hex
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="App">
      {loginToken ? (
        <>
          <Nav />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home seo={seo} />} />
            <Route path="/About" element={<About seo={seo} />} />
            <Route path="/Platforms" element={<Platforms seo={seo} />} />
            <Route path="/Profit-Loss-Calculator" element={<PLC seo={seo} />} />
            <Route path="/Margin-Calculator" element={<MC seo={seo} />} />
            <Route path="/Spread-Calculator" element={<SC seo={seo} />} />
            <Route path="/Help-Center" element={<Help seo={seo} />} />
            <Route path="*" element={<FourOFour />} />
            <Route path="/Blog" element={<Blog seo={seo} />} />
            <Route path="/Privacy-Policy" element={<Terms seo={seo} />} />
            <Route path="/post/:title" element={<Post />} />
            <Route path="/authors/:title" element={<Author />} />
            <Route path="/Account-Types" element={<AccountTypes seo={seo} />} />
            <Route path="/Deposit-&-Withdrawal" element={<DandW seo={seo} />} />
            <Route path="/Market" element={<Instruments seo={seo} />} />
            <Route path="/Promotion" element={<Promotion />} />
            <Route path="/FAQ" element={<FAQ seo={seo} />} />
          </Routes>
          <Footer />
        </>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
