import React, { useEffect, useState } from "react";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";

export default function Hero() {
  const [data, setData] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/help-center`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="ab-hero-c flex-col">
      <h1>help center</h1>
      <img src={data.images ? SERVER_DOMAIN + data.images[0]?.headerImg : ""} />
    </div>
  );
}
