import React, { useEffect, useState } from "react";
import "./articles.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";

export default function Articles({ howTo }) {
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API}/articles?populate=*&sort=createdAt:desc`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="help-articles-c">
      <h2>{howTo ? "how to..." : "articles"}</h2>

      <div className="h-a-c-c">
        {data?.slice(0, howTo ? 4 : 8).map((item, index) => (
          <div
            className="h-a-i"
            key={index}
            onClick={() => {
             const postTitle = item?.attributes?.title
               ?.replace(/\s+/g, "-")
               .replace("?", "");
             navigate(
               {
                 pathname: `/post/${postTitle}`,
                 search: createSearchParams({
                   id: item.id,
                 }).toString(),
               },
               { state: item }
             );
            }}
          >
            <div className="h-a-i-img">
              <img
                src={
                  SERVER_DOMAIN + item?.attributes?.image?.data?.attributes?.url
                }
              />
            </div>

            <div className="h-a-i-content">
              <p className="blog-card-date">
                {new Date(item.attributes.createdAt).toLocaleDateString("TR")}
              </p>
              <h3 className="blog-card-title">{item.attributes.title}</h3>
              <p className="blog-card-des">
                {item.attributes.subTitle.length > 100
                  ? item.attributes.subTitle.slice(0, 90) + "..."
                  : item.attributes.subTitle}
              </p>
            </div>

            <div className="h-a-i-read flex">
              Read
              <ArrowCircleRightOutlinedIcon sx={{ fontSize: 20 }} />
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: 35 }} className="outlined-btn flex">
        See All
      </div>
    </div>
  );
}
