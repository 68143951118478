import React, { useEffect, useState } from "react";
import "./latest-vids.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import VideoPlayer from "../../../../components/video-player/VideoPlayer";

export default function LatestVids() {
  const apiKey = process.env.REACT_APP_API_KEY;
  const [data, setData] = useState([]);
  const [videoPlayer, setVideoPlayer] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API}/videos?filters[lang][$eq]=English&populate=*&sort=createdAt:desc`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="l-v-c">
      <h2>Latest Videos</h2>

      <div className="l-v-m-c-o">
        <div className="l-v-m-c">
          {data.length > 0 &&
            data.slice(0, 4).map((item, index) => (
              <div
                key={index}
                className="l-v-i"
                onClick={() => setVideoPlayer(item?.attributes?.link)}
              >
                <div className="latest-vid-i flex">
                  <img
                    src={
                      SERVER_DOMAIN +
                      item.attributes?.image?.data?.attributes?.url
                    }
                    className="vid-thumbnail"
                  />
                  <img
                    style={{ width: "25%", position: "relative" }}
                    src={require("../../../../assets/icons/play-icon.png")}
                  />
                </div>

                <p style={{ marginTop: 10 }}>{item.attributes?.title}</p>
              </div>
            ))}
        </div>
      </div>

      {videoPlayer && (
        <VideoPlayer setVideoPlayer={setVideoPlayer} link={videoPlayer} />
      )}
    </div>
  );
}
