import React, { useEffect, useState } from "react";
import "./payment-methods.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function PaymentMethods() {
  const [data, setData] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/payment-methods?populate=*`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        setData(data.data);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="pm-c">
      <h3>Deposit funds using any of the available payment method</h3>
      <div>
        <Slider className="pm-c-c" {...settings}>
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className="pm-single"
                onClick={() => window.open(item.attributes.link)}
              >
                <img
                  src={
                    SERVER_DOMAIN +
                    item.attributes?.image?.data?.attributes?.url
                  }
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
