import React from "react";
import "./video-player.css";

export default function VideoPlayer({ link, setVideoPlayer }) {
  return (
    <div className="video-player-c flex" onClick={() => setVideoPlayer(false)}>
      <div className="video-player-m-c flex">
        <iframe
          style={{ width: "80%", aspectRatio: "16/9" }}
          src={
            link &&
            `https://www.youtube.com/embed/${new URL(link).searchParams.get(
              "v"
            )}`
          }
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
}
