import React from "react";
import Important from "../help/sections/important/Important";
import { Helmet } from "react-helmet-async";
import { SERVER_DOMAIN } from "../../assets/staticData";

export default function FAQ({seo}) {

  return (
    <div style={{ padding: "0rem 0 3rem 0" }}>
      <Helmet>
        <title>
          {seo[9]?.metaTitle ? seo[9]?.metaTitle : "Mishov Markets"}
        </title>
        <meta name="description" content={seo[9]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[9]?.socialTitle} />
        <meta property="og:description" content={seo[9]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[9]?.socialImg} />
        <meta name="robots" content={seo[9]?.indexingRules} />
        <meta name="keywords" content={seo[9]?.keywords} />
        <script type="application/ld+json">{seo[9]?.schema}</script>
      </Helmet>
      <Important />
    </div>
  );
}
