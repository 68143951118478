import React from "react";
import "./hero.css";
import { SERVER_DOMAIN } from "../../../../assets/staticData";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import facebookIcon from "../../../../assets/icons/facebook-w.png";
import linkedInIcon from "../../../../assets/icons/linkedin-w.png";

export default function Hero({ user }) {
  return (
    <div className="author-h-c">
      <div
        className="author-h-section-a"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div>
          <img src={SERVER_DOMAIN + user.pfp} />
        </div>
        <div>
          <h2>{user.username}</h2>
          <p>
            • Gender: <span>{user.gender}</span>
          </p>
          <p>
            • Birthday: <span>{user.birthday}</span>
          </p>
          <p>
            • Role: <span>{user.position}</span>
          </p>
          <p>
            • Skills: <span>{user.gender}</span>
          </p>
          <p>
            • Languages: <span>{user.languages}</span>
          </p>
          <p>
            • Education: <span>{user.education}</span>
          </p>
        </div>
        <div>
          {user.socials?.li && (
            <div
              className="author-social-i"
              onClick={() => window.open(user.socials?.linkedIn)}
            >
              <img
                src={linkedInIcon}
                style={{ width: "60%", height: "60%", objectFit: "contain" }}
              />
            </div>
          )}
          {user.socials?.ig && (
            <div
              className="author-social-i"
              onClick={() => window.open(user.socials?.instagram)}
            >
              <InstagramIcon style={{ color: "#fff", fontSize: 20 }} />
            </div>
          )}
          {user.socials?.fb && (
            <div
              className="author-social-i"
              onClick={() => window.open(user.socials?.facebook)}
            >
              <img
                src={facebookIcon}
                style={{ height: "80%", aspectRatio: 1, objectFit: "contain" }}
              />
            </div>
          )}
          {user.socials?.x && (
            <div
              className="author-social-i"
              onClick={() => window.open(user.socials?.x)}
            >
              <XIcon style={{ color: "#fff", fontSize: 18 }} />
            </div>
          )}
        </div>
      </div>
      <div className="author-h-section-b">
        <p>
        {user.bio}
        </p>
      </div>
    </div>
  );
}
