import React from "react";
import "./hero.css";
import Countdown from "../../../../components/countdown/ConutDown";

export default function Hero() {
  return (
    <div className="promo-c flex">
      <img
        className="promo-bg"
        src={require("../../../../assets/images/promo-bg.webp")}
      />
      <div className="promo-c-c">
        <h2>Join Our</h2>
        <h1>Promotion!</h1>
        <p>
          Buy, sell, and grow your trades with Mishov Markets.
          <br />
          The platform dedicated to every trader at any level!
        </p>

        <Countdown targetDate={new Date("2024-09-30T00:00:00")} />
        <div className="promo-date-c">
          <p>15 September 2024 - 3 October 2024</p>
        </div>
      </div>
    </div>
  );
}
