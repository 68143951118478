import React, { useEffect, useState } from "react";
import "./global-market.css";
import { API } from "../../../../assets/staticData";
import { CustomScroll } from "react-custom-scroll";
import {
  AnimatedLine,
  AreaPlot,
  ChartContainer,
  LinePlot,
  areaElementClasses,
  lineElementClasses,
  markElementClasses,
} from "@mui/x-charts";
import { CircularProgress } from "@mui/material";
import AUDUSD_IMG from "../../../../assets/icons/currency-pairs/AUDUSD.png";
import EURUSD_IMG from "../../../../assets/icons/currency-pairs/EURUSD.png";
import GBPUSD_IMG from "../../../../assets/icons/currency-pairs/GBPUSD.png";
import NZDUSD_IMG from "../../../../assets/icons/currency-pairs/NZDUSD.png";
import USDCAD_IMG from "../../../../assets/icons/currency-pairs/USDCAD.png";
import USDCHF_IMG from "../../../../assets/icons/currency-pairs/USDCHF.png";
import USDJPY_IMG from "../../../../assets/icons/currency-pairs/USDJPY.png";
import AUDCAD_IMG from "../../../../assets/icons/currency-pairs/AUDCAD.png";
import AUDJPY_IMG from "../../../../assets/icons/currency-pairs/AUDJPY.svg";
import EURAUD_IMG from "../../../../assets/icons/currency-pairs/EURAUD.png";
import EURCAD_IMG from "../../../../assets/icons/currency-pairs/EURCAD.png";
import EURGBP_IMG from "../../../../assets/icons/currency-pairs/EURGBP.png";
import EURJPY_IMG from "../../../../assets/icons/currency-pairs/EURJPY.png";
import EURNZD_IMG from "../../../../assets/icons/currency-pairs/EURNZD.png";
import GBPAUD_IMG from "../../../../assets/icons/currency-pairs/GBPAUD.png";
import GBPCAD_IMG from "../../../../assets/icons/currency-pairs/GBPCAD.png";
import GBPJPY_IMG from "../../../../assets/icons/currency-pairs/GBPJPY.png";
import NZDJPY_IMG from "../../../../assets/icons/currency-pairs/NZDJPY.png";
import DAX40_IMG from "../../../../assets/icons/currency-pairs/DAX40.png";
import DJI30_IMG from "../../../../assets/icons/currency-pairs/DJI30.png";
import NASUSD_IMG from "../../../../assets/icons/currency-pairs/NASUSD.png";
import SP500_IMG from "../../../../assets/icons/currency-pairs/SP500.png";
import BRENT_IMG from "../../../../assets/icons/currency-pairs/BRENT.png";
import CRUDE_IMG from "../../../../assets/icons/currency-pairs/CRUDE.png";
import XAGUSD_IMG from "../../../../assets/icons/currency-pairs/XAGUSD.png";
import XAUUSD_IMG from "../../../../assets/icons/currency-pairs/XAUUSD.png";
import AUDNZD_IMG from "../../../../assets/icons/currency-pairs/AUDNZD.png";
import CADCHF_IMG from "../../../../assets/icons/currency-pairs/CADCHF.png";
import CHFJPY_IMG from "../../../../assets/icons/currency-pairs/CHFJPY.png";
import EURCHF_IMG from "../../../../assets/icons/currency-pairs/EURCHF.svg";
import AUDCHF_IMG from "../../../../assets/icons/currency-pairs/AUDCHF.svg";
import CADJPY_IMG from "../../../../assets/icons/currency-pairs/CADJPY.svg";
import GBPCHF_IMG from "../../../../assets/icons/currency-pairs/GBPCHF.svg";
import GBPNZD_IMG from "../../../../assets/icons/currency-pairs/GBPNZD.svg";
import NZDCAD_IMG from "../../../../assets/icons/currency-pairs/NZDCAD.svg";
import NZDCHF_IMG from "../../../../assets/icons/currency-pairs/NZDCHF.svg";

export default function GlobalMarket() {
  const [data, setData] = useState([]);
  const [prevBid, setPrevBid] = useState([]);
  const [prevAsk, setPrevAsk] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [filter, setFilter] = useState();
  const [loading, setLoading] = useState(false);

  const apiKey = process.env.REACT_APP_API_KEY;

  const modifyPrices = (data) => {
    return data.map((entry) => {
      const updatedPrices = entry.prices.map((price, index, array) => {
        // Check if the current price is higher than the previous price
        if (index > 0) {
          if (price > array[index - 1]) {
            // Increase the current price by 20%
            return price * 1.2;
          } else if (price < array[index - 1]) {
            // Decrease the current price by 20%
            return price * 0.8;
          }
        }
        return price;
      });
      return { ...entry, prices: updatedPrices };
    });
  };

  async function fetchData() {
    try {
      const response = await fetch(`${API}/global-market`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const dataArray = data.data?.attributes?.data;

      const modifiedForexData = modifyPrices(data.data?.attributes?.chartData);
      setChartData(modifiedForexData);

      setLoading(false);

      if (filter == null) {
        setData(data.data?.attributes?.data || []);

        setPrevBid((prevData) => {
          const newPrevData = [...prevData];

          dataArray.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Bid);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Bid];
            }
          });

          return newPrevData;
        });

        setPrevAsk((prevData) => {
          const newPrevData = [...prevData];

          dataArray.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Ask);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Ask];
            }
          });

          return newPrevData;
        });
      } else if (filter == "major") {
        let filteredData = data.data?.attributes?.data?.filter((item) =>
          item.Symbol.includes("USD")
        );
        setData(filteredData || []);

        setPrevBid((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Bid);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Bid];
            }
          });

          return newPrevData;
        });

        setPrevAsk((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Ask);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Ask];
            }
          });

          return newPrevData;
        });
      } else if (filter == "minor") {
        let filteredData = data.data?.attributes?.data?.filter(
          (item) =>
            !item.Symbol.includes("USD") &&
            item.Symbol !== "BRENT.a" &&
            item.Symbol !== "CRUDE.a" &&
            item.Symbol !== "DAX40.a" &&
            item.Symbol !== "DJI30.a" &&
            item.Symbol !== "SP500.a"
        );
        setData(filteredData || []);

        setPrevBid((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Bid);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Bid];
            }
          });

          return newPrevData;
        });

        setPrevAsk((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Ask);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Ask];
            }
          });

          return newPrevData;
        });
      } else if (filter == "gold&silver") {
        let filteredData = data.data?.attributes?.data?.filter(
          (item) => item.Symbol === "XAUUSD.a" || item.Symbol === "XAGUSD.a"
        );

        setData(filteredData || []);

        setPrevBid((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Bid);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Bid];
            }
          });

          return newPrevData;
        });

        setPrevAsk((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Ask);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Ask];
            }
          });

          return newPrevData;
        });

        const newPrices = filteredData.map((item) => {
          return (item.Ask + item.Bid) / 2;
        });
      } else if (filter == "oil") {
        let filteredData = data.data?.attributes?.data?.filter(
          (item) => item.Symbol === "CRUDE.a" || item.Symbol === "BRENT.a"
        );
        setData(filteredData || []);

        setPrevBid((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Bid);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Bid];
            }
          });

          return newPrevData;
        });

        setPrevAsk((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Ask);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Ask];
            }
          });

          return newPrevData;
        });
      } else if (filter == "index") {
        let filteredData = data.data?.attributes?.data?.filter(
          (item) =>
            item.Symbol === "DAX40.a" ||
            item.Symbol === "DJI30.a" ||
            item.Symbol === "SP500.a" ||
            item.Symbol === "NASUSD.a"
        );
        setData(filteredData || []);

        setPrevBid((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Bid);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Bid];
            }
          });

          return newPrevData;
        });

        setPrevAsk((prevData) => {
          const newPrevData = [...prevData];

          filteredData.forEach((item, index) => {
            if (newPrevData[index]) {
              newPrevData[index].push(item.Ask);

              if (newPrevData[index].length > 3) {
                newPrevData[index].shift();
              }
            } else {
              newPrevData[index] = [item.Ask];
            }
          });

          return newPrevData;
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(fetchData, 2000);

    return () => clearInterval(intervalId);
  }, [filter]);

  return (
    <div className="gm-c">
      <h2>Global Market</h2>
      <div className="gm-content-c">
        <div className="gm-filter-c">
          <div
            className={
              filter == "major"
                ? "gm-filter-item gm-filter-active"
                : "gm-filter-item"
            }
            onClick={() => {
              setLoading(true);
              if (filter !== "major") {
                setFilter("major");
              } else {
                setFilter(null);
              }
            }}
          >
            <p>Forex Major</p>
          </div>
          <div
            className={
              filter == "minor"
                ? "gm-filter-item gm-filter-active"
                : "gm-filter-item"
            }
            onClick={() => {
              setLoading(true);
              if (filter !== "minor") {
                setFilter("minor");
              } else {
                setFilter(null);
              }
            }}
          >
            <p>Forex Minor</p>
          </div>
          <div
            className={
              filter == "index"
                ? "gm-filter-item gm-filter-active"
                : "gm-filter-item"
            }
            onClick={() => {
              setLoading(true);
              if (filter !== "index") {
                setFilter("index");
              } else {
                setFilter(null);
              }
            }}
          >
            <p>Index</p>
          </div>
          <div
            className={
              filter == "oil"
                ? "gm-filter-item gm-filter-active"
                : "gm-filter-item"
            }
            onClick={() => {
              setLoading(true);
              if (filter !== "oil") {
                setFilter("oil");
              } else {
                setFilter(null);
              }
            }}
          >
            <p>Oil</p>
          </div>
          <div
            className={
              filter == "gold&silver"
                ? "gm-filter-item gm-filter-active"
                : "gm-filter-item"
            }
            onClick={() => {
              setLoading(true);
              if (filter !== "gold&silver") {
                setFilter("gold&silver");
              } else {
                setFilter(null);
              }
            }}
          >
            <p>Gold & Silver</p>
          </div>
        </div>
        <div
          className="gm-data-box"
          style={{
            width: "80%",
            height: 500,
            border: "solid",
            borderWidth: 1,
            borderColor: "#ddd",
            margin: "0 auto",
            marginTop: 0,
            borderRadius: 10,
            position: "relative",
          }}
        >
          <div className="gm-table-header">
            <div className="gm-header-item flex" style={{ width: "30%" }}>
              <p>Name</p>
            </div>
            <div className="gm-header-item flex" style={{ width: "15%" }}>
              <p>Bid</p>
            </div>
            <div className="gm-header-item flex" style={{ width: "15%" }}>
              <p>Ask</p>
            </div>
            <div className="gm-header-item flex" style={{ width: "15%" }}>
              <p>Spread</p>
            </div>
            <div className="gm-header-item flex" style={{ width: "25%" }}>
              <p>Chart</p>
            </div>
          </div>

          <CustomScroll heightRelativeToParent="88%" allowOuterScroll>
            {data?.map((item, index) => {
              return (
                <div key={index} className="gm-data-row">
                  <div className="gm-data-item flex" style={{ width: "30%" }}>
                    <div
                      className="flex"
                      style={{ justifyContent: "flex-start", width: "38%" }}
                    >
                      {item.Symbol.includes("AUDUSD") && (
                        <img src={AUDUSD_IMG} className="gm-icon" />
                      )}
                      {item.Symbol.includes("EURUSD") && (
                        <img
                          src={EURUSD_IMG}
                          className="gm-icon"
                          alt="EURUSD"
                        />
                      )}
                      {item.Symbol.includes("GBPUSD") && (
                        <img
                          src={GBPUSD_IMG}
                          className="gm-icon"
                          alt="GBPUSD"
                        />
                      )}
                      {item.Symbol.includes("NZDUSD") && (
                        <img
                          src={NZDUSD_IMG}
                          className="gm-icon"
                          alt="NZDUSD"
                        />
                      )}
                      {item.Symbol.includes("USDCAD") && (
                        <img
                          src={USDCAD_IMG}
                          className="gm-icon"
                          alt="USDCAD"
                        />
                      )}
                      {item.Symbol.includes("USDCHF") && (
                        <img
                          src={USDCHF_IMG}
                          className="gm-icon"
                          alt="USDCHF"
                        />
                      )}
                      {item.Symbol.includes("USDJPY") && (
                        <img
                          src={USDJPY_IMG}
                          className="gm-icon"
                          alt="USDJPY"
                        />
                      )}
                      {item.Symbol.includes("AUDCAD") && (
                        <img
                          src={AUDCAD_IMG}
                          className="gm-icon"
                          alt="AUDCAD"
                        />
                      )}
                      {item.Symbol.includes("AUDCHF") && (
                        <img
                          src={AUDCHF_IMG}
                          className="gm-icon"
                          alt="AUDCHF"
                        />
                      )}
                      {item.Symbol.includes("AUDJPY") && (
                        <img
                          src={AUDJPY_IMG}
                          className="gm-icon"
                          alt="AUDJPY"
                        />
                      )}
                      {item.Symbol.includes("CADJPY") && (
                        <img
                          src={CADJPY_IMG}
                          className="gm-icon"
                          alt="CADJPY"
                        />
                      )}
                      {item.Symbol.includes("EURAUD") && (
                        <img
                          src={EURAUD_IMG}
                          className="gm-icon"
                          alt="EURAUD"
                        />
                      )}
                      {item.Symbol.includes("EURCAD") && (
                        <img
                          src={EURCAD_IMG}
                          className="gm-icon"
                          alt="EURCAD"
                        />
                      )}
                      {item.Symbol.includes("EURCHF") && (
                        <img
                          src={EURCHF_IMG}
                          className="gm-icon"
                          alt="EURCHF"
                        />
                      )}
                      {item.Symbol.includes("EURGBP") && (
                        <img
                          src={EURGBP_IMG}
                          className="gm-icon"
                          alt="EURGBP"
                        />
                      )}
                      {item.Symbol.includes("EURJPY") && (
                        <img
                          src={EURJPY_IMG}
                          className="gm-icon"
                          alt="EURJPY"
                        />
                      )}
                      {item.Symbol.includes("EURNZD") && (
                        <img
                          src={EURNZD_IMG}
                          className="gm-icon"
                          alt="EURNZD"
                        />
                      )}
                      {item.Symbol.includes("GBPAUD") && (
                        <img
                          src={GBPAUD_IMG}
                          className="gm-icon"
                          alt="GBPAUD"
                        />
                      )}
                      {item.Symbol.includes("GBPCAD") && (
                        <img
                          src={GBPCAD_IMG}
                          className="gm-icon"
                          alt="GBPCAD"
                        />
                      )}
                      {item.Symbol.includes("GBPCHF") && (
                        <img
                          src={GBPCHF_IMG}
                          className="gm-icon"
                          alt="GBPCHF"
                        />
                      )}
                      {item.Symbol.includes("GBPJPY") && (
                        <img
                          src={GBPJPY_IMG}
                          className="gm-icon"
                          alt="GBPJPY"
                        />
                      )}
                      {item.Symbol.includes("GBPNZD") && (
                        <img
                          src={GBPNZD_IMG}
                          className="gm-icon"
                          alt="GBPNZD"
                        />
                      )}
                      {item.Symbol.includes("NZDCAD") && (
                        <img
                          src={NZDCAD_IMG}
                          className="gm-icon"
                          alt="NZDCAD"
                        />
                      )}
                      {item.Symbol.includes("NZDCHF") && (
                        <img
                          src={NZDCHF_IMG}
                          className="gm-icon"
                          alt="NZDCHF"
                        />
                      )}
                      {item.Symbol.includes("NZDJPY") && (
                        <img
                          src={NZDJPY_IMG}
                          className="gm-icon"
                          alt="NZDJPY"
                        />
                      )}
                      {item.Symbol.includes("DAX40") && (
                        <img
                          src={DAX40_IMG}
                          className="gm-icon gm-icon-not-pairs"
                          alt="DAX40"
                        />
                      )}
                      {item.Symbol.includes("DJI30") && (
                        <img
                          src={DJI30_IMG}
                          className="gm-icon gm-icon-not-pairs"
                          alt="DJI30"
                        />
                      )}
                      {item.Symbol.includes("NASUSD") && (
                        <img
                          src={NASUSD_IMG}
                          className="gm-icon gm-icon-not-pairs"
                          alt="NASUSD"
                        />
                      )}
                      {item.Symbol.includes("SP500") && (
                        <img
                          src={SP500_IMG}
                          className="gm-icon gm-icon-not-pairs"
                          alt="SP500"
                        />
                      )}
                      {item.Symbol.includes("BRENT") && (
                        <img
                          src={BRENT_IMG}
                          className="gm-icon gm-icon-not-pairs"
                          alt="BRENT"
                        />
                      )}
                      {item.Symbol.includes("CRUDE") && (
                        <img
                          src={CRUDE_IMG}
                          className="gm-icon gm-icon-not-pairs"
                          alt="CRUDE"
                        />
                      )}
                      {item.Symbol.includes("XAGUSD") && (
                        <img
                          src={XAGUSD_IMG}
                          className="gm-icon gm-icon-not-pairs"
                          alt="XAGUSD"
                        />
                      )}
                      {item.Symbol.includes("XAUUSD") && (
                        <img
                          src={XAUUSD_IMG}
                          className="gm-icon gm-icon-not-pairs"
                          alt="XAUUSD"
                        />
                      )}
                      {item.Symbol.includes("AUDNZD") && (
                        <img
                          src={AUDNZD_IMG}
                          className="gm-icon"
                          alt="AUDNZD"
                        />
                      )}
                      {item.Symbol.includes("CADCHF") && (
                        <img
                          src={CADCHF_IMG}
                          className="gm-icon"
                          alt="CADCHF"
                        />
                      )}
                      {item.Symbol.includes("CHFJPY") && (
                        <img
                          src={CHFJPY_IMG}
                          className="gm-icon"
                          alt="CHFJPY"
                        />
                      )}

                      <p>
                        {item.Symbol == "BRENT.a" ||
                        item.Symbol == "CRUDE.a" ||
                        item.Symbol == "DJI30.a" ||
                        item.Symbol == "SP500.a" ||
                        item.Symbol == "DAX40.a"
                          ? item.Symbol.slice(0, 5)
                          : item.Symbol.slice(0, 3) +
                            "/" +
                            item.Symbol.slice(3, 6)}
                      </p>
                    </div>
                  </div>
                  <div className="gm-data-item flex" style={{ width: "15%" }}>
                    <p>{item.Bid}</p>
                    <div
                      className="gm-data-item-with-bg"
                      style={{
                        backgroundColor:
                          item.Bid == prevBid[index][0]
                            ? "transparent"
                            : item.Bid > prevBid[index][0]
                            ? "#2bbb2b27"
                            : "#ff000040",
                        borderColor:
                          item.Bid == prevBid[index][0]
                            ? "transparent"
                            : item.Bid > prevBid[index][0]
                            ? "#2bbb2b80"
                            : "#ff000060",
                      }}
                    ></div>
                  </div>
                  <div className="gm-data-item flex" style={{ width: "15%" }}>
                    <p>{item.Ask}</p>
                    <div
                      style={{
                        backgroundColor:
                          item.Ask == prevAsk[index][0]
                            ? "transparent"
                            : item.Ask > prevAsk[index][0]
                            ? "#2bbb2b27"
                            : "#ff000040",
                        borderColor:
                          item.Ask == prevAsk[index][0]
                            ? "transparent"
                            : item.Ask > prevAsk[index][0]
                            ? "#2bbb2b80"
                            : "#ff000060",
                      }}
                      className="gm-data-item-with-bg"
                    ></div>
                  </div>
                  <div className="gm-data-item flex" style={{ width: "15%" }}>
                    <p>{(item.Ask - item.Bid).toFixed(6)}</p>
                  </div>
                  <div className="gm-data-item flex" style={{ width: "25%" }}>
                    <ChartContainer
                      width={230}
                      height={145}
                      series={[
                        {
                          type: "line",
                          data: chartData.find((i) => i.id == item.Symbol)
                            .prices,
                          area: true,
                        },
                      ]}
                      xAxis={[
                        {
                          scaleType: "point",
                          data: [...Array(12).keys()],
                        },
                      ]}
                      sx={{
                        [`& .${lineElementClasses.root}`]: {
                          stroke: "var(--green)",
                          strokeWidth: 2,
                        },
                        [`& .${markElementClasses.root}`]: {
                          stroke: "var(--green)",
                          scale: "0.6",
                          fill: "#fff",
                          strokeWidth: 2,
                        },
                        [`& .${areaElementClasses.root}`]: {
                          fill: "#2bbb2b40",
                        },
                      }}
                      disableAxisListener
                    >
                      <LinePlot />
                      <AreaPlot />
                    </ChartContainer>
                  </div>
                </div>
              );
            })}
          </CustomScroll>

          <div
            className="gm-loading flex"
            style={{
              opacity: loading ? 1 : 0,
              visibility: loading ? "visible" : "hidden",
            }}
          >
            <CircularProgress style={{ color: "#00166A" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
