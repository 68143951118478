import React, { useEffect, useState } from "react";
import "./important.css";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import parse from "html-react-parser";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

export default function Important() {
  const [hovered, setHovered] = useState("");
  const [selected, setSelected] = useState("");
  const [data, setData] = useState([]);
  const [openIndex, setOpenIndex] = useState(-1);
  const apiKey = process.env.REACT_APP_API_KEY;
  
  useEffect(() => {
    setOpenIndex(0);
  }, [selected]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/help-center`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data?.data?.attributes?.importamtTopics[0]?.menu);
        setSelected(data?.data?.attributes?.importamtTopics[0]?.menu[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="help-imp-c">
      <div className="help-tabs-c">
        <h2>important topics</h2>

        <div className="help-search-c">
          <MagnifyingGlassIcon width={25} style={{ color: "var(--primary)" }} />
          <input className="help-search-input" placeholder="Search..." />
        </div>

        <div className="help-tabs-m-c">
          {data?.map((item, index) => (
            <div
              className={`help-tab flex ${
                selected.title == item.title ? "help-tab-active" : null
              }`}
              key={index + 1}
              onMouseEnter={() => setHovered(index + 1)}
              onMouseLeave={() => setHovered("")}
              onClick={() => {
                setSelected(item);
              }}
            >
              <img
                className={
                  selected.title == item.title ? "help-tab-active-img" : null
                }
                src={SERVER_DOMAIN + item.icon}
              />
              <p>{item.title}</p>
            </div>
          ))}
        </div>

        <div className="help-content-c">
          <div className="help-faqs">
            {selected.faqs?.map((item, index) => (
              <FAQItem
                key={index}
                question={item.q}
                answer={item.a}
                isOpen={index === openIndex}
                toggleOpen={() => {
                  if (index == openIndex) {
                    setOpenIndex(-1);
                  } else {
                    setOpenIndex(index);
                  }
                }}
              />
            ))}
          </div>
          <div className="help-vids">
            {selected.vid1 && (
              <div className="video-container">
                <iframe
                  src={`https://www.youtube.com/embed/${new URL(
                    selected.vid1
                  ).searchParams.get("v")}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            )}
            {selected.vid2 && (
              <div className="video-container">
                <iframe
                  src={`https://www.youtube.com/embed/${new URL(
                    selected.vid2
                  ).searchParams.get("v")}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            )}
            {selected.vid3 && (
              <div className="video-container">
                <iframe
                  src={`https://www.youtube.com/embed/${new URL(
                    selected.vid3
                  ).searchParams.get("v")}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function FAQItem({ question, answer, isOpen, toggleOpen }) {
  return (
    <div className="help-faqs-i">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={toggleOpen}
      >
        <h3 style={{ fontSize: 20, textAlign: "left" }}>{question}</h3>
        <ExpandCircleDownIcon sx={{ fontSize: 30 }} />
      </div>

      <div
        className="help-faqs-a-c"
        style={{
          opacity: isOpen ? 1 : 0,
          maxHeight: isOpen ? "1000px" : "0",
          overflow: "hidden",
          transition: "0.2s",
        }}
      >
        {parse(answer)}
      </div>
    </div>
  );
}
