import React from "react";
import Hero from "./sections/hero/Hero";
import Important from "./sections/important/Important";
import Videos from "./sections/videos/Videos";
import Question from "../about/sections/question/Question";
import Articles from "./sections/articles/Articles";
import Call from "./sections/call/Call";
import { Helmet } from "react-helmet-async";
import { SERVER_DOMAIN } from "../../assets/staticData";
export default function Help({seo}) {
  return (
    <div>
      <Helmet>
        <title>
          {seo[4]?.metaTitle ? seo[4]?.metaTitle : "Mishov Markets"}
        </title>
        <meta name="description" content={seo[4]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[4]?.socialTitle} />
        <meta property="og:description" content={seo[4]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[4]?.socialImg} />
        <meta name="robots" content={seo[4]?.indexingRules} />
        <meta name="keywords" content={seo[4]?.keywords} />
        <script type="application/ld+json">{seo[4]?.schema}</script>
      </Helmet>
      <Hero />
      <Call />
      <Articles />
      <Videos />
      <Question />
    </div>
  );
}
